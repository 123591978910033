import { makeStyles } from "@material-ui/core/styles";
import { InfoIcon } from '@panwds/icons';
import { Tag, Tooltip } from "@panwds/react-ui";
import { useCallback, useEffect, useState } from 'react';
import { useHistory, withRouter } from 'react-router-dom';
import * as DataTypes from "../../api/FwaasDataTypes";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { PANTitle, PANWDSBannerContainer, toast } from "../../components";
import { PANWDSTable } from '../../components/PANWDSElements';
import { usePermissions, useTranslate } from '../../customHooks';
import { dataProvider } from "../../dataProvider";
import Emitter from '../../eventEmitter';
import { nameStyleCursor } from '../../layout/styles';
import { ReduxActions, ReduxResources } from "../../redux";
import { RouteUri } from '../../routeUri';
import { checkCSPBanner } from "../../utils";
import { getNotificationList, renderNotificationBanner } from "../../utils/checkSWUpgradeBanner";
import { ApplicationConfigManager } from "../../types";
import { getFirewallName } from "../firewallsV2/firewallsUtil";

let localSelectedRegion: string;

const useStyles = makeStyles((theme) => ({
  iconedParagraph: {
    display: 'flex',
    alignItems: 'center',
    marginTop: '5px',
    gap: '10px',
  },
}));

const FirewallList = ({ readyOnly = false, RuleStackName = undefined, customTitle, rulestackScreen = false }: any) => {
  const nameClass = nameStyleCursor();
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const reduxState = useAppSelector((state) => state);
  const firewallsState = reduxState.firewalls;

  const [gridData, setGridData] = useState(firewallsState.listDetails.data);
  const [loading, setLoading] = useState(firewallsState.listDetails.loading);
  const [nextToken, setNextToken]: any = useState();
  const [gridRef, setGridRef] = useState({
    current: {
      columns: []
    },
  });

  const translate = useTranslate();
  const history = useHistory();
  const { permissions } = usePermissions();
  const [panoramas, setPanoramas] = useState<any[] | undefined>(undefined);
  const [cloudManagers, setCloudManagers] = useState<any[] | undefined>(undefined);
  const [links, setLinks] = useState<any>({});
  const [linkData, setLinkData] = useState<any[]>([]);
  const [notificationList, setNotificationList] = useState<any[]>([]);
  const title = customTitle ? customTitle : translate(`resources.firewalls.name`);

  // const inProgressStates = ['CREATING', 'UPDATING', 'DELETING' ];
  // const inProgressFirewalls = gridData.filter( (fws: any) => inProgressStates.includes(fws.Status.FirewallStatus));

  // const updateInProgressFirewalls = () => {
  //   const MAX_REFRESH_CALLS = 10;
  //   const inProgressFirewalls = gridData.filter( (fws: any) => inProgressStates.includes(fws.Status.FirewallStatus)).slice(0, MAX_REFRESH_CALLS);
  //
  //   Promise.all(inProgressFirewalls.map(
  //     (fw: any) => dataProvider.describe('firewalls', '', { FirewallName: fw.Firewall.FirewallName, AccountId: fw.Firewall.AccountId }))
  //   )
  //     .then((responses: any) => {
  //       let updatedFW = responses.filter( (res:any) => !inProgressStates.includes(res.data.Status.FirewallStatus));
  //       if (updatedFW.length > 0){
  //         let newGrid: any = [];
  //         gridData.map((record: any)=> {
  //           updatedFW.map((res:any) => {
  //             if (record.Firewall.FirewallName === res.data.Firewall.FirewallName){
  //               record.Status.FirewallStatus = res.data.Status.FirewallStatus;
  //             }
  //           });
  //           newGrid.push(record);
  //         });
  //         //@ts-ignore
  //         setGridData([...newGrid]);
  //       }
  //     });
  // };

  const handleRegionChange = (region: any) => {
    if (nextToken) {
      setNextToken(undefined);
    }
      loadGridData(true);
      loadGridData(true);
  }

  useEffect(() => {
    if (reduxState.firewalls.listDetails.data && reduxState.firewalls.listDetails.data.length > 0) {
         setNotificationList(getNotificationList(reduxState.firewalls.listDetails.data));
    }
 }, [reduxState.firewalls.listDetails.data]);

  useEffect(() => {
    checkCSPBanner({translate, reduxState});
  }, [reduxState.support.cspEnable]);

  useEffect(() => {
    Emitter.on('regionChanged', handleRegionChange);
    return () => {
      Emitter.off('regionChanged', handleRegionChange);
      dataProvider.abort('firewalls');
    }
  }, []);

    useEffect(() => {
        setGridData(firewallsState.listDetails.data);
        setNextToken(firewallsState.listDetails.nextToken);
        if (firewallsState.listDetails.loading && !loading) {
            setLoading(true);
        }
        if (!firewallsState.listDetails.loading && loading) {
            setLoading(false);
            Emitter.emit("regionLoadingData", false);
        }
        if (firewallsState.listDetails.error) {
            toast.error(firewallsState.listDetails.error, { toastId: `firewall-list` });
        }
    }, [firewallsState.listDetails.data, firewallsState.listDetails.loading]);


    const refreshGrid = () => {
        dispatch(ReduxActions.resetListDetails({resource: ReduxResources.FIREWALL})({}))
            .then(() => {
                loadGridData(true);
            });
    }

    const loadLinkData = () => {
        let apiCall: { resource: string, url?: string, payload?: any } = {
            resource: "settings",
            payload: { panorama: true }
        }
        if (process.env.REACT_APP_SHOW_CM === "true") {
            apiCall = {
                resource: "integrations",
            }
        }

      dataProvider.describe(apiCall.resource, apiCall.url || "", apiCall?.payload || {})
          .then(async (response: DataTypes.IFwaasApiResponse) => {
              let links = Object.keys(response.data.Links);
              let cmLinks: Array<any> = [];
              let pnLinks: Array<any> = [];
              links.map(link => {
                  const value = response.data.Links[link].LinkName ? `${link} (${response.data.Links[link].LinkName})` : link;
                  if (response.data.Links[link]?.hasOwnProperty('CloudManager') && value !== undefined) {
                      cmLinks.push(value);
                  }
                  if (response.data.Links[link]?.hasOwnProperty('Panorama') && value !== undefined) {
                      pnLinks.push(value);
                  }
              })
              setPanoramas(pnLinks);
              setCloudManagers(cmLinks);
              setLinks(response?.data?.Links);
          })
          .catch((e: any) => {
              toast.error(e?.error?.error, {toastId: "support-describe"});
          })
    };

  const loadGridData = (needRefresh = false) => {
      loadLinkData();
    if (!permissions?.ListFirewalls) {
      if (permissions && !permissions?.ListFirewalls) {
        // TODO toast is not showing, but if we add toast container it will show
        // many times until permissions is loaded, need to rethink this logic
        toast.warning(translate("permissions.cantView"));
      }
      setLoading(false);
      return;
    }
    if ((gridData.length && nextToken) || !gridData.length || needRefresh) {
      if (!gridData.length || needRefresh) {
        setGridData([]);
        setLoading(true);
        Emitter.emit("regionLoadingData", true);
      }
      setLoading(true);
      dispatch(ReduxActions.fetchListDetails({resource: ReduxResources.FIREWALL})({ data: ({ NextToken: (nextToken) ? nextToken : undefined, RuleStackName: RuleStackName }), needRefresh }));
    } else {
        dispatch(ReduxActions.compareList({resource: ReduxResources.FIREWALL})({}));
    }
  }

  const getIntegrationType = (LinkId: any) => {
    if(LinkId) {
      if (cloudManagers) {
        let foundCMLinkId = cloudManagers?.find((cloudManager) => cloudManager.includes(LinkId));
        if(foundCMLinkId) {
          return `${translate(`resources.firewalls.fields.StrataCloudManager`)} (${foundCMLinkId})`
        }
      }
      if (panoramas) {
        let foundPanLinkId = panoramas?.find((panorama) => panorama?.includes(LinkId));
        if(foundPanLinkId) {
          return `${translate(`resources.firewalls.fields.Panorama`)} (${foundPanLinkId})`
        }
      }
    } else {
      return translate(`resources.firewalls.fields.LocalRulestack`)
    }
  }

  const getUrlLink = (LinkId: string) => {
    const url = links?.[LinkId]?.CloudManager?.Url;
    return url
  }

  const getcolumns = () => [
    {
      //id: 'FirewallName',
      accessor: 'Firewall.FirewallName',
      Header: translate(`resources.firewalls.fields.FirewallName`),
      columnSize: readyOnly ? 5 : 2,
      render: ({ row }: any) => <span
        className={nameClass.blueColor}
        onClick={(evt) => onRowClick(row, evt)}>
        {ApplicationConfigManager.getInstance().getConfig().tenantVersion === "V2"
        ? getFirewallName(row?.original?.Firewall)
        : row?.original?.Firewall.FirewallName}
      </span>,
    },
    {
      //id: 'FirewallName',
      accessor: 'Firewall.FirewallId',
      Header: translate(`resources.firewalls.fields.FirewallId`),
      columnSize: 1.5,
      render: ({ row }: any) => <span>{row?.original?.Firewall.FirewallId}</span>,
    },
    {
      accessor: 'Status.FirewallStatus',
      Header: translate(`resources.firewalls.fields.Status`),
      columnSize: 1,
      noTooltip: true,
      render: ({ row }: any) =>
        <div className={classes.iconedParagraph}> {row?.original?.Status?.FirewallStatus}
          {(row?.original?.Status?.FailureReason &&
            <Tooltip label={row?.original?.Status?.FailureReason}>
              <InfoIcon size="xs" />
            </Tooltip>
          )}
        </div>
    },
    {
      accessor: 'Endpoints',
      Header: translate(`resources.firewalls.fields.Endpoints`),
      columnSize: 1,
      noTooltip: true,
      render: ({ row }: any) => <span>{row?.original?.Status?.Attachments?.length}</span>,
    },
    {
      accessor: 'LinkId',
      Header: translate(`resources.firewalls.fields.PolicyManagement`),
      columnSize: 1.5,
      noTooltip: true,
      render: ({ row }: any) => {
        let linkId = getIntegrationType(row?.original?.Firewall?.LinkId)
        if(linkId?.includes('Strata Cloud Manager')) {
          return <a target="_blank" href={getUrlLink(row?.original?.Firewall?.LinkId)}>{linkId}</a>;
        }
        return <span>{linkId}</span>;
      },
    },
      {
          accessor: 'EgressNAT',
          Header: translate(`resources.firewalls.fields.EgressNAT.EgressNatEnabled`),
          columnSize: 1.2,
          noTooltip: true,
          render: ({ row }: any) => {
              return <span>{(row?.original?.Firewall?.EgressNAT?.Enabled) ? "Yes" : "No"}</span>;
          },
      },
    {
      accessor: 'Rulestacks',
      Header: translate(`resources.firewalls.fields.Rulestacks`),
      columnSize: 2,
      noTooltip: true,
      render: ({ row }: any) => {
        let rulestack = (row?.original?.Firewall?.RuleStackName && row?.original?.Firewall?.GlobalRuleStackName) ?
          <span><b>Local: </b>{row?.original?.Firewall?.RuleStackName}<br /><b>Global: </b>{row?.original?.Firewall?.GlobalRuleStackName}</span> :
          (row?.original?.Firewall?.RuleStackName) ? <span><b>Local: </b>{row?.original?.Firewall?.RuleStackName}</span> :
            (row?.original?.Firewall?.GlobalRuleStackName) ?
              <span><b>Global: </b>{row?.original?.Firewall?.GlobalRuleStackName}</span> : "";

        return <Tooltip label={rulestack}><span>{rulestack}</span></Tooltip>
      }
    },
    {
      accessor: 'Firewall.Tags',
      Header: translate(`resources.rules.fields.Tags`),
      columnSize: readyOnly ? 3 : 2,
      noTooltip: true, // don't remove
      render: ({ row }: any) =>
        <span>
          {(row?.original?.Firewall?.Tags?.length > 0 && row?.original?.Firewall?.Tags?.map((tag: any) => {
            return (<Tag key={tag.Key} readOnly style={{ marginRight: '5px' }}>
              {tag.Key} | {tag.Value}
            </Tag>)
          }))}
        </span>,
      disableSortBy: true
    },
    {
      accessor: 'Firewall.AccountId',
      Header: translate(`resources.firewalls.fields.AccountId`),
      columnSize: 1,
      render: ({ row }: any) => <span>{row?.original?.Firewall.AccountId}</span>
    }
  ];

  const deleteAction = (selected: any) => {
    if (!permissions.DeleteFirewall) {
      toast.warning(translate("permissions.cantExecute"));
      return;
    }
    if (selected && Array.isArray(selected)) {
      const successItems: any = [];
      const failedItems: any = [];
        selected.map((item: any) => {
            dataProvider.delete("firewalls", {id: item.Firewall.FirewallName, previousData: item.Firewall})
                .then(async (response: any) => {
                    successItems.push(item);
                }).catch((e: any) => {
                    failedItems.push({item: item, e});
            }).finally(() => {
                if (failedItems.length + successItems.length === selected.length) { // request finished
                    //let ids = successItems.map((item: any) => item.id);
                    //setGridData((prev: any[]) => ([...prev.filter((record: any) => !ids.includes(record.id))]));
                    //setGridKey(gridKey + 1);
                    dispatch(ReduxActions.deleteResource({resource: ReduxResources.FIREWALL})({deletedItems: successItems}));
                    setLoading(false);
                    if (successItems.length > 0) {
                        let message = (
                            <>
                                {translate(`resources.firewalls.fields.firewallDeletionMessage`)}
                                <ul>{successItems.map((item: any) => <li> - {item.Firewall.FirewallName}</li>)}</ul>
                            </>
                        );
                        toast.success(message);
                    }
                    if (failedItems.length > 0) {
                        toast.error(<>
                            {translate(`resources.firewalls.fields.firewallDeletionErrorMessage`)}
                            {<ul>{failedItems.map(({item, e}: any) => <li
                                key={item}> - {item.Firewall.FirewallName} ({e?.error?.error})</li>)}</ul>}
                        </>);
                    }
                }
            });
        });
    }
  }

  const onRowClick = useCallback((rowProps, event) => {
    if (!permissions?.DescribeFirewall) {
      return;
    }
    if (ApplicationConfigManager.getInstance().getConfig().tenantVersion === "V2") {
      sessionStorage.setItem("fwEditActiveTab", "overview");
      history.push(RouteUri.NGFirewallEdit
        .replace(":firewallname", rowProps.original.Firewall.FirewallId)
        + "?region=" + rowProps.original.Firewall.Region);
    } else {
      history.push(RouteUri.NGFirewallRules
        .replace(":firewallname", rowProps.original.Firewall.FirewallName)
        + "?AccountId=" + rowProps.original.Firewall.AccountId);
    }
  }, [history, permissions]);

  const toolbarActions: any[] = [];
  toolbarActions.push({
      actionsMap: [],
      handleButtonAction: () => refreshGrid(),
      type: "icon",
  })
  if (permissions?.DeleteFirewall) {
    toolbarActions.push({
      title: translate(`common.actions`),
      actionsMap: [{ menuText: translate(`common.delete`), handleAction: deleteAction, confirmModal: 'delete', dataMetrics: "cloudngfw-firwall-delete-btn" },]
    });
  }
  if (permissions?.CreateFirewall) {
      toolbarActions.push({
          title: translate(`resources.firewalls.fields.CreateButton`),
          actionsMap: [],
          dataMetrics: "cloudngfw-firewall-create-btn",
          handleButtonAction: () => history.push(RouteUri.NGFirewallCreate),
          type: 'button',
          appearance: 'primary',
          disabled: !permissions?.CreateFirewall,
          showTooltip: !reduxState.support.cspEnable
      });
  }

  const filterColumns = () => {
    return getcolumns().filter((column: any) => {
      if (column.accessor === "EgressNAT" && process.env.REACT_APP_ENABLE_EGRESS_NAT === "false") {
        return column.accessor !== "EgressNAT";
      } else if (column.accessor === "Rulestacks" && readyOnly) {
        return column.accessor !== "Rulestacks";
      } else {
        return column;
      }
    })
  }

  return (readyOnly ?
    <PANWDSTable
      setHandle={setGridRef}
      columns={filterColumns()}
      infiniteScroll={false}
      overflowTable={true}
      loadGridData={loadGridData}
      permissions={permissions}
      isBackgroundFetching={nextToken}
      gridData={gridData}
      showSelectGroupBy={false}
      title={title}
      idProperty={"id"}
      showToolbar={false}
      dropDownActionsArray={toolbarActions}
      showTileTitle={true}
      emptyTitle={translate(`resources.firewalls.fields.EmptyTitle`)}
      emptySubtitle={translate(`resources.firewalls.fields.EmptySubtitle`)}
      loading={loading}
      singleSelect={false}
      multiSelect={false}
      useOldToolbarButtons={true}
      offsetTableHeight={rulestackScreen ? 315 : 200}
      dataMetrics="cloudngfw-firewall-table"
      dataTestId="cloudngfw-firewall-table"
    /> : <>
      <PANTitle
        region
        divider
        title={title}
        paddingContainer="16px"
      />
      <PANWDSBannerContainer/>
      <>
      {((notificationList && notificationList.length > 0) &&
         notificationList.map((item: any) => {
            return renderNotificationBanner(item);
         }
      ))}
      </>
      <PANWDSTable
        setHandle={setGridRef}
        columns={filterColumns()}
        infiniteScroll={false}
        overflowTable={true}
        loadGridData={loadGridData}
        permissions={permissions}
        isBackgroundFetching={nextToken}
        gridData={gridData}
        showSelectGroupBy={false}
        title={title}
        subtitle={translate(`resources.firewalls.fields.ListSubtitle`)}
        idProperty={"RuleStackName"}
        showToolbar={true}
        dropDownActionsArray={toolbarActions}
        showTileTitle={true}
        emptyTitle={translate(`resources.firewalls.fields.EmptyTitle`)}
        emptySubtitle={translate(`resources.firewalls.fields.EmptySubtitle`)}
        loading={loading}
        singleSelect={false}
        multiSelect={true}
        useOldToolbarButtons={true}
        offsetTableHeight={rulestackScreen ? 315 : 200}
        tableMinHeight={600}
        searchFilterRequired={true}
        dataMetrics="cloudngfw-firewall-table"
        dataTestId="cloudngfw-firewall-table"
      />
      {
        // do not enable until describe param for list firewalls is implemented, otherwise will add ever more request for this page
        // inProgressFirewalls.length > 0 ? <IntervalCallerComponent updateCallback={updateInProgressFirewalls} /> : null
      }
    </>
  );
}

export default withRouter(FirewallList);
