import { PANTitle, toast } from '../../components';
import { PANWDSBreadcrumbs } from "../../components/PANWDSElements";
import { useParams, withRouter } from "react-router-dom";
import { useTranslate } from '../../customHooks';
import { Tab, TabList, TabPanel, Tabs } from '@panwds/react-ui';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { makeStyles } from '@material-ui/core';
import { FirewallGeneralInfo, FirewallOverview, FirewallUserID, FirewallEndpoints, FirewallEgressNat, FirewallLogProfile, FirewallSecurityPolicies } from './Components/Edit';
import { useGetFirewallByIdQuery } from '../../redux/services/firewalls-service';
import { useGetLinkAccountsQuery } from '../../redux/services/accounts-service';
import RulesList from '../rulesV2/RulesList';
import { useGetFeatureFlagsQuery } from '../../redux/services/settings-service';

const useStyles = makeStyles((theme) => ({
    customTabs: {
        '& div[role="tab"][class*="tw-bg-white"]': {
            '& *': {
                fontSize: '14px',
                fontWeight: '400',
                color: '#333333',
            },
        },
        '& div[role="tab"]:not([class*="tw-bg-white"])': {
            '& *': {
                fontSize: '14px',
                fontWeight: '600',
            },
            borderLeft: '3px solid #004C9D'
        },
    },

    tabListWrapper: {
        width: '11.25rem',
        minWidth: '11.25rem',
        flexShrink: 0,
        margin: '16px 0',
    },
    tabsContainer: {
        display: 'flex',
        flexDirection: 'row',
        height: 'calc(100vh - 6.5rem)',
        width: '100%',
    },
    tabPanels: {
        flex: 1,
        overflow: 'auto',
        display: 'flex',
        flexDirection: 'column',
        margin: '16px 16px 16px 0',
    },
    tabPanelContent: {
        flex: 1,
        overflow: 'auto',
        display: 'flex',
        flexDirection: 'column',
        borderLeft: '4px solid #DADBDB',
    },
    whiteBackground: {
        backgroundColor: 'white',
        flex: 1,
        padding: '16px',
        margin: 0
    }
}));

const FirewallEdit = (props: any) => {
    const translate = useTranslate();
    const { firewallname } = useParams<{ firewallname: string }>();
    const [activeTabId, setActiveTabId] = useState<string>(sessionStorage.getItem('fwEditActiveTab') || 'overview')
    const [nextToken, setNextToken] = useState<string | null>(null);
    const [accountList, setAccountsList] = useState<Record<string, unknown>[]>([]);
    const [proxyAccountDetails, setProxyAccoutDetails] = useState<{ accountId: number, region: string }>({});

    const breadcrumbMapping = {
        ngfirewalls: `${translate('resources.firewallsV2.shortTitle')}`,
        [firewallname]: firewallname,
    };
    const classes = useStyles();
    const region = useMemo(() => new URLSearchParams(location.search).get('region'), [location.search]) || undefined;

    const { data: firewall, error, isLoading: fwDetailsLoading, isFetching: isFwDetailsFetching, refetch } = useGetFirewallByIdQuery({ fwid: firewallname, region });

    const handleTabChange = useCallback((tabId: string) => {
        sessionStorage.setItem('fwEditActiveTab', tabId);
        setActiveTabId(tabId);
        if (tabId !== "logProfile") {
            refetch();
        }
    }, [])

    const { data: accounts, error: accountsError, isLoading } = useGetLinkAccountsQuery({
        nexttoken: nextToken || '',
        maxresults: 1000,
        onboarded: true,
        describe: true,
        region: region,
    }, {
        skip: nextToken === undefined,
    });

    useEffect(() => {
        if (accounts && accounts.items) {
            setAccountsList((prevItems) => [...prevItems, ...accounts.items]);
            setNextToken(accounts.nextToken);
        }
        if (accounts && accounts.serviceAccount) {
            setProxyAccoutDetails({ accountId: accounts.serviceAccount, region: accounts.mpRegion || "us-east-1" });
        }
      }, [accounts]);

    if (accountsError) {
        toast.error(accountsError?.error, { toastId: "firewall-edit-get-accounts" });
    }

    // Function to refresh the firewall data
    const refreshFirewallData = () => {
        refetch();
    };

    const { data: featureFlags, error: settingsError, isLoading: isSettingsLoading } = useGetFeatureFlagsQuery({ featureflags: true, region: region });

    return (
        <>
            <PANWDSBreadcrumbs
                mapping={breadcrumbMapping}
            />
            <PANTitle
                title={firewallname}
            />
            <PANTitle divider={false} />
            <div className={classes.tabsContainer}>
                <div className={classes.tabListWrapper}>
                    <Tabs orientation="vertical" addClassName={classes.customTabs}>
                        <TabList activeTabId={activeTabId} onActiveChange={nextTabId => handleTabChange(nextTabId)}>
                            <Tab id="overview">
                                {translate('resources.firewallsV2.overview')}
                            </Tab>
                            <Tab id="rules">
                                {translate('resources.firewallsV2.rules')}
                            </Tab>
                            <Tab id="general">
                                {translate('resources.firewallsV2.generalInfo')}
                            </Tab>
                            <Tab id="policies">
                                {translate('resources.firewallsV2.policyManager')}
                            </Tab>
                            <Tab id="egress">
                                {translate('resources.firewallsV2.egressNat')}
                            </Tab>
                            {(featureFlags?.UserID == true) && <Tab id="userId">
                                {translate('resources.firewallsV2.userid.title')}
                            </Tab>}
                            <Tab id="endpoints">
                                {translate('resources.firewallsV2.endpointManagement.title')}
                            </Tab>
                            <Tab id="logProfile">
                                {translate('resources.firewallsV2.logMetrics.title')}
                            </Tab>
                        </TabList>
                    </Tabs>
                </div>
                <div className={classes.tabPanels}>
                    <div className={classes.tabPanelContent}>
                        <TabPanel addClassName={classes.whiteBackground} activeTabId={activeTabId} forTabId="overview">
                            <FirewallOverview fwid={firewallname} region={region} key={firewall?.Firewall?.UpdateToken + firewall?.Firewall?.DeploymentUpdateToken} firewallData={firewall} isLoading={fwDetailsLoading || isFwDetailsFetching} refreshFirewallData={refreshFirewallData} handleTabChange={handleTabChange} featureFlags={featureFlags} />
                        </TabPanel>
                        <TabPanel addClassName={classes.whiteBackground} activeTabId={activeTabId} forTabId="rules">
                            <RulesList key={firewall?.Firewall?.UpdateToken + firewall?.Firewall?.DeploymentUpdateToken} data={firewall} isLoading={fwDetailsLoading || isFwDetailsFetching} />
                        </TabPanel>
                        <TabPanel addClassName={classes.whiteBackground} activeTabId={activeTabId} forTabId="general">
                            <FirewallGeneralInfo key={firewall?.Firewall?.UpdateToken + firewall?.Firewall?.DeploymentUpdateToken} firewallData={firewall} isLoading={fwDetailsLoading || isFwDetailsFetching} refreshFirewallData={refreshFirewallData} />
                        </TabPanel>
                        <TabPanel addClassName={classes.whiteBackground} activeTabId={activeTabId} forTabId="policies">
                            <FirewallSecurityPolicies key={firewall?.Firewall?.UpdateToken + firewall?.Firewall?.DeploymentUpdateToken} firewallData={firewall} isLoading={fwDetailsLoading || isFwDetailsFetching} refreshFirewallData={refreshFirewallData} />
                        </TabPanel>
                        <TabPanel addClassName={classes.whiteBackground} activeTabId={activeTabId} forTabId="egress">
                            <FirewallEgressNat key={firewall?.Firewall?.UpdateToken + firewall?.Firewall?.DeploymentUpdateToken} firewallData={firewall} isLoading={fwDetailsLoading || isFwDetailsFetching} refreshFirewallData={refreshFirewallData} />
                        </TabPanel>
                        {(featureFlags?.UserID == true) && <TabPanel addClassName={classes.whiteBackground} activeTabId={activeTabId} forTabId="userId">
                            <FirewallUserID key={firewall?.Firewall?.UpdateToken + firewall?.Firewall?.DeploymentUpdateToken} firewallData={firewall} isLoading={fwDetailsLoading || isFwDetailsFetching} refreshFirewallData={refreshFirewallData} />
                        </TabPanel>}
                        <TabPanel addClassName={`${classes.whiteBackground} ${classes.noMargin}`} activeTabId={activeTabId} forTabId="endpoints">
                            <FirewallEndpoints key={firewall?.Firewall?.UpdateToken + firewall?.Firewall?.DeploymentUpdateToken} accounts={accountList} firewallData={firewall} isLoading={fwDetailsLoading || isFwDetailsFetching} refreshFirewallData={refreshFirewallData} />
                        </TabPanel>
                        <TabPanel addClassName={`${classes.whiteBackground} ${classes.noMargin}`} activeTabId={activeTabId} forTabId="logProfile">
                            <FirewallLogProfile accounts={accountList} proxyAccountDetails={proxyAccountDetails} />
                        </TabPanel>
                    </div>
                </div>
            </div>
        </>
    );
};
export default withRouter(FirewallEdit);
