import React from 'react';
import { useTranslate } from '../../../../../customHooks';
import { getLogsMetricsEndpoints } from '../../../firewallsUtil';
import { Link } from '@panwds/react-ui';
interface LogMetricsProps {
    firewall: any;
    styles: any;
}

const LogsMetricsOverview: React.FC<LogMetricsProps> = ({ firewall, styles }) => {
    const translate = useTranslate();
    const logConfig = firewall?.LogConfig;

    const logDestinationType = logConfig?.LogDestinationType || "None";
    const logType = logConfig?.LogType?.join(', ') || "Disabled";
    const policyType = logConfig?.RoleType || "None";
    const logDestination = logConfig?.LogDestination;
    const cloudWatchNamespace = logConfig?.CloudWatchNamespace;
    const logDestinationRegion = logConfig?.LogDestinationRegion || "us-east-1"

    const getLogDestinationUrl = (): any => {
        if (logDestination) {
            return getLogsMetricsEndpoints(logDestinationType, logDestination, 'Logs', logDestinationRegion);
        } else if (cloudWatchNamespace) {
            return getLogsMetricsEndpoints(undefined, undefined, 'Metrics', logDestinationRegion);
        }
        return '';
    };

    const logDestinationUrl = getLogDestinationUrl();

    return (
        <div className={styles.flexContainer}>
            <h2 className={styles.title}>{translate(`resources.firewallsV2.logMetrics.title`)}</h2>
            <div className={styles.flexColumn}>
                <div className={styles.flexRow}>
                    <strong className={styles.flexRowHeader}>{translate(`resources.firewallsV2.logMetrics.logDestinationType`)}</strong>
                    <span className={styles.flexRowValues}>{logDestinationType}</span>
                </div>
                <div className={styles.flexRow}>
                    <strong className={styles.flexRowHeader}>{translate(`resources.firewallsV2.logMetrics.logType`)}</strong>
                    <span className={styles.flexRowValues}>{logType}</span>
                </div>
                <div className={styles.flexRow}>
                    <strong className={styles.flexRowHeader}>{translate(`resources.firewallsV2.logMetrics.policyType`)}</strong>
                    <span className={styles.flexRowValues}>{policyType}</span>
                </div>
                <div className={styles.flexRow}>
                    <strong className={styles.flexRowHeader}>{translate(`resources.firewallsV2.logMetrics.logDestination`)}</strong>
                    <span className={styles.flexRowValues}>
                        {logDestinationUrl ? (
                             <div className={styles.truncatedContainer}>
                                <span className={styles.truncatedText}>
                                    <Link
                                        external
                                        href={logDestinationUrl}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className="text-blue-500"
                                    >
                                        {logDestinationUrl || "None"}
                                    </Link>
                                </span>
                            </div>
                            ) : (
                                "None"
                            )}
                    </span>
                </div>
            </div>
        </div>
    );
};

export default LogsMetricsOverview;
