import { Backdrop, CircularProgress, Grid, Link as MuiLink } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CheckCircle from '@material-ui/icons/CheckCircle';
import Warning from '@material-ui/icons/Warning';
import { isEmpty } from 'lodash';
import { useTranslate } from '../../customHooks';
import { useCallback, useEffect, useState } from "react";
import { useHistory, withRouter } from 'react-router-dom';
import { RouteUri } from '../../routeUri';
import { Button as PANWDSButton, InfoIcon, Tooltip } from "@panwds/react-ui";
import * as DataTypes from "../../api/FwaasDataTypes";
import * as EndPoints from "../../api/FwaasUriEndPoints";
import { PANTile, PANTitle, PANWDSBannerContainer, SaveButton, toast } from '../../components';
import { dataProvider } from "../../dataProvider";
import { Support } from '../../images';
import { PANWDSModal } from '../../components/PANWDSElements';
import { usePermissions } from '../../customHooks';
import {useAppSelector} from "../../app/hooks";
import {checkCSPBanner} from "../../utils";

const useStyles = makeStyles((theme) => ({
  header: {
    border: '1px solid #DADBDB',
  },
  pantileContainer: {
    display: 'grid',
    gridTemplateColumns: '100%',
    gap: 16,
    margin: 16,
  },
  supportIcon: {
    width: '268.21px',
    height: '192.9px'
  },
  supportRequirementDiv: {
    backgroundColor: '#FFF6D5',
    borderRadius: '4px',
    display: 'grid',
    gridTemplateColumns: '30px calc(100% - 30px)',
    padding: '10px',
    fontSize: '12px',
    lineHeight: '20px',
    margin: '10px 0px 10px 0px',
    color: '#333333',
  },
  supportInfo: {
    lineHeight: '20px',
    fontSize: '12px',
    color: '#333333',
  },
  status: {
    lineHeight: '24px',
    fontWeight: 'bolder',
    fontSize: '12px',
    color: '#006FCC'
  },
  backdrop: {
    position: "absolute",
    zIndex: 101,
    backgroundColor: "rgba(0, 0, 0, 0.05)",
    overflow: "hidden",
  },
  subscriptionContainer: {
    fontSize: "12px",
    color: "#333333",
    lineHeight: "24px",
  },
  subscriptionHeader: {
    display: 'grid',
    gridTemplateColumns: "35% 15% 20% 10% 20%",
    fontWeight: 'bolder',
    borderBottom: "1px solid #ccc",
  },
  subscriptionDetail: {
    display: 'grid',
    gridTemplateColumns: "15px calc(35% - 15px) 15% 20% 10% 20%",
    borderBottom: "1px solid #ccc",
  },
  nameColumn: {
    paddingLeft: '15px'
  },
  circleActive: {
    width: '12px',
    height: '12px',
    borderRadius: '6px',
    backgroundColor: '#33CCB8',
  },
  iconAndText: {
    display: 'flex',
    alignItems: 'center',
    gap: '5px',
    "& svg": {
      color: "#000000",
    }
  },
  checkIcon: {
    color: '#006FCC',
    fontSize: '14px',
    marginBottom: "-2px",
  },
  subscriptionNonActive: {
    color: "#707070",
  },
  buttonStyling: {
    marginTop: '10px'
  }
}));

const SubscriptionEdit = (props: any) => {
  const classes = useStyles();
  const { permissions } = usePermissions();
  const reduxState = useAppSelector((state) => state);
  const translate = useTranslate();
  const [updating, setUpdating] = useState(false);
  const [record, setRecord] = useState<any>(undefined);
  const [openConfirm, setOpenConfirm] = useState(false);
  const [subscriptionInfo, setSubscriptionInfo] = useState<any>(undefined);

  const getEndDate = (type: any, endDate: any, status: any) => {
    let endDateMsg: any = translate(`resources.support.subscription.na`);
    let convertedDate: string = endDate?.split("-").join("/");
    let convertDateNoMilliSecs = convertedDate?.split('.')[0]; //milli secs removed for support in safari
    if (endDate !== 'N/A') {
      if (type === "FreeTrial") {
        endDateMsg = translate(`resources.support.subscription.trialEndDateMessage`) + " " + (new Date(endDate)).toLocaleString();
      } else if(status === "Active" && (type === "PAYG30Day" || type === "PAYG15Day")) {
        endDateMsg = translate(`resources.support.subscription.trialEndDateMessage`) + " " + new Date(convertDateNoMilliSecs).toLocaleString();
      } else {
        endDateMsg = new Date(convertDateNoMilliSecs).toLocaleString();
      }
    }
    return endDateMsg;
  }

  const processBillingInfo = (billingInfo: any[]) => {
    let paygActive = false;
    let saasContractActive = false;

    // if we have saas contract active and a valid payg
    billingInfo.map((info: any) => {
      if (info.Type === "PAYG") {
        paygActive = info.Status === "Active";
      }
      if (info.Type === "Contract") {
        saasContractActive = info.Status === "Active";
      }
    });

    billingInfo.sort((a: any, b: any) => {
        if (a.Status === 'Active' && b.Status !== 'Active') {
          return -1; // a comes first
        } else if (a.Status !== 'Active' && b.Status === 'Active') {
          return 1; // b comes first
        } else {
          return 0; // keep original order if both have the same status
        }
      });


    const processedInfo: any[] = billingInfo.map((info: any) => {
      if (info.Status === "Expired") {
        return {
          rowClass: classes.subscriptionNonActive,
          checkMark: "",
          name: info.Name,
          status: (<div>{info.Status}</div>),
          totalCredits: info.Credit,
          endDate: getEndDate(info.Type, info.EndDate, info.Status),
          accountId: info.AccountId
        };
      } else if ((info.Type === "PAYG30Day" || info.Type === "PAYG15Day") && paygActive && saasContractActive) {
        return {
          rowClass: classes.subscriptionNonActive,
          checkMark: "",
          name: info.Name,
          status: (
            <div className={classes.iconAndText}>
              {translate(`resources.support.subscription.overriden`)}
              <Tooltip label={translate(`resources.support.subscription.overridenInfo`)}>
                <InfoIcon size='xs' />
              </Tooltip>
            </div>
          ),
          totalCredits: info.Credit,
          endDate: getEndDate(info.Type, info.EndDate, info.Status),
          accountId: info.AccountId
        };
      } else if (info.Status === "Active") {
        return {
          rowClass: "",
          checkMark: <CheckCircle className={classes.checkIcon} fontSize="small" />,
          name: info.Name,
          status: <><div className={classes.circleActive}></div><div>{info.Status}</div></>,
          totalCredits: info.Credit,
          endDate: getEndDate(info.Type, info.EndDate, info.Status),
          accountId: info.AccountId
        }
      } else if(info.Status === "Inactive") {
        return {
          rowClass: classes.subscriptionNonActive,
          checkMark: "",
          name: info.Name,
          status: (<div>{info.Status}</div>),
          totalCredits: info.Credit,
          endDate: getEndDate(info.Type, info.EndDate, info.Status),
          accountId: info.AccountId
        };
      }
    });
    return processedInfo.filter((info: any) => info);
  };

  const getBillingInfo = useCallback(() => {
    dataProvider.describe("settings")
      .then(async (response: DataTypes.IFwaasApiResponse) => {
        if (response.data?.BillingInfo) {
          setSubscriptionInfo(processBillingInfo(response.data.BillingInfo));
        }
      })
      .catch((e: any) => {
        toast.error(e?.error);
      });
  }, []);

    useEffect(() => {
        checkCSPBanner({translate, reduxState});
    }, [reduxState.support.cspEnable]);

  useEffect(() => {
    dataProvider.describe("support").
      then(async (response: DataTypes.IFwaasApiResponse) => {
        if (response.data) {
          setRecord(response.data.SupportInformations[0] || {});
        } else {
          toast.error(response?.error?.error, { toastId: "support-describe" });
          setRecord({});
        }
      })
      .catch((e: any) => {
        toast.error(e?.error, { toastId: "support-describe" });
        setRecord({});
      })
      .finally(() => {
        setUpdating(false);
      });
    getBillingInfo();
  }, []);

  const handleChange = () => {
    setOpenConfirm(!openConfirm);
  };

  const update = () => {
    setUpdating(true);
    //@ts-ignore
    dataProvider.update("support", { SupportType: EndPoints.SUPPORT_ENHANCED })
      .then(async (response: DataTypes.IFwaasApiResponse) => {
        if (response.data) {
          setRecord({ ...record, SupportType: EndPoints.SUPPORT_ENHANCED });
          setOpenConfirm(false);
        } else {
          toast.error(response?.error?.error, { toastId: "support-update" });
        }
      })
      .catch((e: any) => {
        toast.error(e?.error, { toastId: "support-update" });
      }).finally(() => {
        setUpdating(false);
      })
  };

  const SubscriptionTile = ({ subscriptionInfo }: any) => {
    const classes = useStyles();
    const translate = useTranslate();

    return (
      <PANTile
        title={translate(`resources.support.subscription.title`)}
        size={12}
        titlePadding="12px 16px"
        padding="17px"
      >
        <div className={classes.subscriptionContainer}>
          <div className={classes.subscriptionHeader}>
            <div className={classes.nameColumn}>{translate(`resources.support.subscription.name`)}</div>
            <div>{translate(`resources.support.subscription.status`)}</div>
            <div>{translate(`resources.support.subscription.accountId`)}</div>
            <div>{translate(`resources.support.subscription.creditsTotal`)}</div>
            <div>{translate(`resources.support.subscription.endDate`)}</div>
          </div>
          {subscriptionInfo.map((info: any) => (
            <div className={`${classes.subscriptionDetail} ${info.rowClass}`}>
              <div>{info.checkMark}</div>
              <div>{info.name}</div>
              <div className={classes.iconAndText}>{info.status}</div>
              <div>{info.accountId}</div>
              <div>{info.totalCredits}</div>
              <div>{info.endDate}</div>
            </div>
          ))}
        </div>
      </PANTile>
    );
  };

  return (
    <>
      <PANTitle title={translate(`resources.support.title`)} paddingContainer="16px" />
      <PANWDSBannerContainer/>
      <div className={classes.pantileContainer}>
        {subscriptionInfo && <SubscriptionTile subscriptionInfo={subscriptionInfo} />}
        <PANTile
          title={translate(`resources.support.panels.support`)}
          size={12}
          titlePadding="12px 16px"
          padding="17px"
          divider
        >
          <Grid container style={{ gap: 16, width: 'auto' }}>
            <Grid item xs={6}>
              <div className={classes.supportInfo}>{translate(`resources.support.fields.supportInfo`)}</div>
              <div className={classes.supportInfo}>{translate(`resources.support.fields.supportInfoSecondPara`)}</div>
              {(isEmpty(record) || record?.Status === EndPoints.STATUS_PENDING) &&
                <>
                  <div className={classes.supportRequirementDiv}>
                    <div style={{ verticalAlign: 'center' }}>
                      <Warning htmlColor='#EF9700' />
                    </div>
                    <div>
                      {translate(`resources.support.fields.supportRequirement`)}
                    </div>
                  </div>

                </>
              }
              {(record && record.SupportType === EndPoints.SUPPORT_ENHANCED) ?
                <div className={classes.status}>{translate(`resources.support.values.subscribed`)}</div>
                :
                <div className={classes.buttonStyling}>
                  <PANWDSButton
                    size="md"
                    appearance={(!record || record?.Status === EndPoints.STATUS_PENDING || record.SupportType === EndPoints.SUPPORT_ENHANCED) ? "secondary" : "primary"}
                    disabled={!permissions.UpdateSubscription || isEmpty(record) || record?.Status === EndPoints.STATUS_PENDING || record.SupportType === EndPoints.SUPPORT_ENHANCED}
                    onClick={handleChange}
                    dataMetrics="cloudngfw-rulestack-edit-cancel-button"
                  >
                    {translate('resources.support.fields.subscribe')}
                  </PANWDSButton>
                </div>
              }
            </Grid>
            <Grid item xs={3}>
              <img src={Support} alt="Support" className={classes.supportIcon} />
            </Grid>
          </Grid>
        </PANTile>
      </div>
      <PANWDSModal
        title={translate(`resources.support.confirm.title`)}
        onClose={() => setOpenConfirm(false)}
        isOpen={openConfirm}
        size="sm"
        dataTestId="cloudngfw-subscription-edit-confirm-modal"
        dataMetrics="cloudngfw-subscription-edit-modal"
        modalBody={<div>{translate(`resources.support.confirm.description`)}</div>}
        modalBodyStylingRequired
        modalFooter={{
          footerRequired: true,
          cancelButtonRequired: true,
          featureSpecificButton: <SaveButton
            appearance="primary"
            size="md"
            label={translate('resources.support.values.subscribe')}
            redirect={false}
            onClick={() => update()}
            disabled={!permissions.UpdateSubscription}
            saving={updating}
            submitOnEnter={false}
            {...props}
          />
        }}
      />
      <Backdrop className={classes.backdrop} open={record === undefined}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
};
export default withRouter(SubscriptionEdit);
