import { FormLayout, SubmitButton, Textarea } from '@panwds/react-form';
import { Banner, Button, Sheet, SheetBody } from "@panwds/react-ui";
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslate } from "../../../../../customHooks";
import { isEmpty } from 'lodash';
import { validateAwsAccounts } from '../../../../../utils/validate';
import { RouteUri } from '../../../../../routeUri';


export const FirewallAllowlistedAccounts = (props: any) => {
    const { close, fwRecord, onSubmit, accounts } = props;
    const translate = useTranslate();

    const formMethods = useForm({ defaultValues: fwRecord });
    const {
        formState: { dirtyFields, isValid }
    } = formMethods

    return (
        <>
         <Sheet
            onClose={() => close()}
            title={translate(`resources.firewallsV2.endpointManagement.manageAllowlistedAccount`)}
            isOpen
            showMask={true}
            position="md"
        >
            <SheetBody>
                <div style={{ position: 'relative' }}>
                    {   accounts?.length === 0 &&
                        <>
                            <div className='tw-pb-4'>
                                <Banner
                                    type="inline"
                                    appearance="warning"
                                    showIcon
                                    onClick={(e) => {
                                        e.preventDefault();
                                        history.push(RouteUri.AccountList)
                                    }}
                                    actions={
                                        <>
                                            <Button appearance="primary" size="sm" dataMetrics={"cloudngfw-swupgrade-notification-redirect"} onClick={(e) => {
                                                e.preventDefault();
                                                history.push(RouteUri.AccountList)
                                            }}
                                            >
                                                {translate(`resources.firewallsV2.onboardAwsAccount`)}
                                            </Button>
                                        </>
                                    }
                                >
                                    {translate(`resources.firewallsV2.endpointManagement.addEndpointNoOnboardedAccountBaner`)}
                                </Banner>
                            </div>
                        </>
                    }
                    <FormProvider {...formMethods}>
                        <form onSubmit={formMethods.handleSubmit(onSubmit)}>
                            <FormLayout>
                                <div>
                                    <Textarea
                                        label={translate(`resources.firewallsV2.endpointManagement.allowListedAccounts`)}
                                        name="Firewall.AllowListAccounts"
                                        dataMetrics="cloudngfw-firewall-edit-allowlisted-aws-accounts"
                                        register={{ required: false, validate: validateAwsAccounts }}
                                        description={translate(`resources.firewallsV2.endpointManagement.firewallAllowlistedAwsAccountsEditDescription`)}
                                    />

                                    <div className="tw-flex tw-justify-end">
                                        <Button addClassName='tw-mr-2'
                                            appearance={"secondary"}
                                            onClick={() => props?.close()}>
                                            {translate("generic.cancel")}
                                        </Button>
                                        <SubmitButton disabled={isEmpty(dirtyFields)}>{translate(`generic.save`)}</SubmitButton>
                                    </div>
                                </div>
                            </FormLayout>
                        </form>
                    </FormProvider>
                </div>
            </SheetBody>
        </Sheet>
    </>
    );
};
