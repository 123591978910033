import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { prepareHeaders, buildError, API } from '../../api/apis'; // Adjust the import path as needed

// Define the RTK Query service for settings
export const settingsService = createApi({
  reducerPath: 'settingsApi',
  baseQuery: fetchBaseQuery({
    prepareHeaders: prepareHeaders,
  }),
  endpoints: (builder) => ({
    getFeatureFlags: builder.query({
      query: (requestSettings: any) => {
        const result = API.FETCH_SETTINGS(requestSettings);
        return result || ''; // Ensure a valid string is returned
      },
      transformResponse: (response: any) => {
        const featureFlags = response?.Response?.FeatureFlags || [];
        return featureFlags;
      },
      transformErrorResponse: (response) => {
        return buildError(response, "");
      },
    }),
  }),
});

// Export hooks for usage in functional components
export const { useGetFeatureFlagsQuery } = settingsService;
