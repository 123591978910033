import React from 'react';
import { useTranslate } from '../../../../../customHooks';
import { formatDeletionProtection, getFirewallName, getFirewallStateDisplay } from '../../../firewallsUtil';
import { Tooltip } from '@panwds/react-ui';
import { CircleCheckIcon, ExclamationCircleIcon } from '@panwds/icons';

interface GeneralInfoProps {
    firewall: any;
    status: any;
    styles: any;
}

const GeneralInfoOverview: React.FC<GeneralInfoProps> = ({ firewall, status, styles }) => {
    const translate = useTranslate();

    return (
        <div className={styles.flexContainer}>
            <h2 className={styles.title}>{translate(`resources.firewallsV2.generalInfo`)}</h2>
            <div className={styles.flexColumn}>
                <div className={styles.flexRow}>
                    <strong className={styles.flexRowHeader}>{translate(`resources.firewallsV2.firewallId`)}</strong>
                    <span className={styles.flexRowValues}>{firewall?.FirewallId}</span>
                </div>
                <div className={styles.flexRow}>
                    <strong className={styles.flexRowHeader}>{translate(`resources.firewallsV2.firewallStatus`)}</strong>
                    <span className={styles.flexRowValues}>{getFirewallStateDisplay(status)}</span>
                </div>
                <div className={styles.flexRow}>
                    <strong className={styles.flexRowHeader}>{translate(`resources.firewallsV2.firewallCommitStatus`)}</strong>
                    <span className={styles.flexRowValues}>
                        {status?.DeviceRuleStackCommitStatus === "Failed" ?
                        <>
                            <div className="tw-flex">
                                <Tooltip label={status?.DeviceRuleStackCommitInfo?.CommitMessages?.[0] || ""}>
                                    <ExclamationCircleIcon
                                        size="xs"
                                        className="tw-text-red-500 dark:tw-text-dark-bg-red"
                                    />
                                </Tooltip>
                                &nbsp; {status?.DeviceRuleStackCommitStatus}
                            </div>
                        </>
                        : status?.DeviceRuleStackCommitStatus === "Success" ? <>
                            <CircleCheckIcon size="xs" className="tw-text-teal-600 dark:tw-text-dark-bg-teal"/>
                                &nbsp; {status?.DeviceRuleStackCommitStatus}
                            </>
                        : status?.DeviceRuleStackCommitStatus
                        }
                    </span>
                </div>
                <div className={styles.flexRow}>
                    <strong className={styles.flexRowHeader}>{translate(`resources.firewallsV2.firewallName`)}</strong>
                    <span className={styles.flexRowValues}>{getFirewallName(firewall)}</span>
                </div>
                <div className={styles.flexRow}>
                    <strong className={styles.flexRowHeader}>{translate(`resources.firewallsV2.region`)}</strong>
                    <span className={styles.flexRowValues}>{firewall?.Region}</span>
                </div>
                <div className={styles.flexRow}>
                    <strong className={styles.flexRowHeader}>{translate(`resources.firewallsV2.availabilityZoneIDs`)}</strong>
                    <span className={styles.flexRowValues}>{firewall?.CustomerZoneIdList?.join(', ')}</span>
                </div>
                <div className={styles.flexRow}>
                    <strong className={styles.flexRowHeader}>{translate(`resources.firewallsV2.deleteProtection`)}</strong>
                    <span className={styles.flexRowValues}>
                        {firewall?.DeletionProtection ? formatDeletionProtection(firewall.DeletionProtection) : '-'}
                    </span>
                </div>
            </div>
        </div>
    );
};

export default GeneralInfoOverview;
