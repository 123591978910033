import {createBrowserHistory} from 'history'
import {routerMiddleware} from 'connected-react-router'
import { createRootReducer } from '../redux'
import { firewallsService } from '../redux/services/firewalls-service'
import { logProfileService } from '../redux/services/log-profile-service'

export const history = createBrowserHistory()

import { configureStore } from '@reduxjs/toolkit'
import { regionsService } from '../redux/services/regions-service'
import { userRolesService } from '../redux/services/user-roles-service'
import { rulesService } from '../redux/services/rules-service'
import { accountsService } from '../redux/services/accounts-service'
import { policiesService } from '../redux/services/policies-service'
import { settingsService } from '../redux/services/settings-service'


const services = [
    firewallsService,
    regionsService,
    userRolesService,
    rulesService,
    accountsService,
    logProfileService,
    policiesService,
    settingsService
    // Add more services here as needed
];

const middleware = [
    routerMiddleware(history),
    ...services.map(service => service.middleware),
];

export const store = configureStore({
    reducer: createRootReducer(history),
    middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(middleware),
    devTools: process.env.NODE_ENV !== 'production',
})

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch

// export default function configureStore(preloadedState: { router?: { location: { query: { [x: string]: string | undefined }; pathname: string; search: string; state: unknown; hash: string; key?: string | undefined }; action: Action } | undefined } | undefined) {
//     return createStore(
//         createRootReducer(history), // root reducer with router state
//         preloadedState,
//         compose(
//             applyMiddleware(
//                 routerMiddleware(history), // for dispatching history actions
//                 // ... other middlewares ...
//             ),
//         ),
//     )
// }
