import { makeStyles } from "@material-ui/core/styles";
import { useEffect, useState } from 'react';
import { usePermissions, useTranslate } from '../../customHooks';
import { withRouter } from 'react-router-dom';
import { CircleCheckIcon, UnlinkIcon, BanIcon } from '@panwds/icons';
import { IntervalCallerComponent, PANTitle, PANWDSBannerContainer, toast } from '../../components';
import { dataProvider } from "../../dataProvider";
import { PANWDSTableLight } from '../../components/PANWDSElements';
import { ITableToolbar } from "../../types";
import * as DataTypes from "../../api/FwaasDataTypes";
import { nameStyleCursor } from '../../layout/styles';
import { AddEditPolicyPanel } from "./components/AddEditPolicyPanel";
import { ErrorUnlinkModal, NotificationCMModal, NotificationModal, SelectCDLModal } from './Modals';
import { ApplicationConfigManager } from '../../types';
import SecurityServices from "./SecurityServices";

import _ from "lodash";
import { generateCloudManagerIntegrationsMap, getCSPIdFromSN, getCSPIdFromTenantName, getRegion } from "./utils";
import { IntegrationsDetailPanel } from "./components/IntegrationsDetailPanel";
import {useAppSelector} from "../../app/hooks";
import {checkCSPBanner} from "../../utils";

const useStyles = makeStyles((theme) => ({
    cellStyles: {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        paddingRight: '20px',
    },
    iconAndText: {
        display: 'flex',
        alignItems: 'center',
        gap: '5px',
    },
    circle: {
        width: '12px',
        height: '12px',
        borderRadius: '6px',
    },
    circleActive: {
        backgroundColor: '#33CCB8',
    },
    circleInActive: {
        backgroundColor: '#707070;',
    },
    circlePending: {
        backgroundColor: '#EF9700',
    },
    enabledColor: {
        color: '#33CCB8',
    },
    disabledColor: {
        color: '#EF9700',
    },
    action: {
        cursor: 'pointer',
    }
}));

type PanelType = "" | "panoramaDetail" | "addPanorama" | "editPanorama" | "editLog" | "addCDL" | "cloudManagerDetail" | "securityServices";
type ModalType = "alreadyLinked" | "notLinked" | "selectCDL" | "unlinkConfirm" | "unlinkCMConfirm" | "unlinkError" | "";

const IntegrationsList = () => {
    const nameClass = nameStyleCursor();
    const classes = useStyles();
    const translate = useTranslate();
    const { permissions } = usePermissions();
    const reduxState = useAppSelector((state) => state);
    const [gridData, setGridData] = useState<any[]>([]);
    const [loading, setLoading] = useState(false);
    const [taskInProgress, setTaskInProgress] = useState(false);
    const [panoramas, setPanoramas] = useState<any>(undefined);
    const [cloudManagers, setCloudManagers] = useState<any>(undefined);
    const [selectedPanorama, setSelectedPanorama] = useState<any>(undefined);
    const [regions, setRegions] = useState<any>(undefined);
    // const [nextToken, setNextToken] = useState<any>(undefined);

    const [sidePanel, setSidePanel] = useState<PanelType>("");
    const [modalState, setModalState] = useState<ModalType>("");
    const [dgs, setDGS] = useState<any>({ FMSOnboarded: false, CloudDGS: [] });

    const anyUnlinkPending = gridData.some(link => link?.hasOwnProperty('Panorama') && link.Status === 'Pending');
    const [showBackButton, setShowBackButton] = useState(false);

    // if `FixedRegionCode` is found in the supported regions, ForceApiUrl will be used in settings update
    const FixedRegionCode = "us-east-1";
    let ForceApiUrl = ApplicationConfigManager.getInstance().getSupportedRegions().find(v => v.RegionCode === FixedRegionCode)?.ApiUrl;
    if (ForceApiUrl) ForceApiUrl += '/v1';

    useEffect(() => {
        checkCSPBanner({translate, reduxState});
    }, [reduxState.support.cspEnable]);

    const loadData = async () => {
        setLoading(true);

        dataProvider.describe("integrations").then(async (response: DataTypes.IFwaasApiResponse) => {
            setGridData(Object.keys(response.data.Links).map(link => ({ ...response.data.Links[link], LinkId: link })));

        })
            .catch((e: any) => {
                toast.error(e?.error?.error, { toastId: "support-describe" });
            })
            .finally(() => setLoading(false));
        // dataProvider.describe("settings", '', { panorama: true })
        //     .then(async (response: DataTypes.IFwaasApiResponse) => {
        //         setGridData(Object.keys(response.data.Links).map(link => ({ ...response.data.Links[link], LinkId: link })));
        //     })
        //     .catch((e: any) => {
        //         toast.error(e?.error, { toastId: "settings-describe" });
        //     })
        //     .finally(() => setLoading(false));

        dataProvider.describe("regions")
            .then(async (response: DataTypes.IFwaasApiResponse) => setRegions({ ...response.data.Regions }))
            .catch((e: any) => {
                toast.error(e?.error, { toastId: "regions-describe" });
            });

        dataProvider.describe("panorama").then(async (response: DataTypes.IFwaasApiResponse) => {
            setPanoramas(response.data);
        })
            .catch((e: any) => {
                toast.error(e?.error?.error, { toastId: "support-describe" });
            });

        dataProvider.describe("cloudManager")
            .then(async (response: DataTypes.IFwaasApiResponse) => setCloudManagers(response.data ))
            .catch((e: any) => {
                toast.error(e?.error, { toastId: "regions-describe" });
            });
    };

    // filter panoramas sn already taken
    const getAvailablePanoramas = () => {
        if (panoramas) {
            const takenSerialNumbers = gridData.map((row: any) => {
                // ignore selected one so it can appear on edit mode
                if (selectedPanorama && row.Panorama?.PrimarySN === selectedPanorama?.Panorama?.PrimarySN) {
                    return [];
                }
                return row.Panorama?.SecondarySN ? [row.Panorama?.PrimarySN, row.Panorama?.SecondarySN] : [row.Panorama?.PrimarySN];
            }).flat();
            return {
                IntegrationsAll: panoramas?.IntegrationsAll?.map(
                    (integrationsList: any) => {
                        return {
                            ...integrationsList,
                            Integrations: integrationsList.Integrations.filter(
                                (integration: any) => !takenSerialNumbers.includes(integration.Panorama.SerialNumber)
                            )
                        };
                    })
            };
        }
        return panoramas;
    };

    const getAvailableCloudManager = () => {
        if (cloudManagers) {
            return {
                IntegrationsAll: cloudManagers?.IntegrationsAll?.map(
                    (integrationsList: any) => {
                        return {
                            ...integrationsList,
                            Integrations: integrationsList.Integrations
                        };
                    })
            };
        }
        return cloudManagers;
    };

    const updateGridData = (settings: any) => {
        // update specific settings panorama
        setGridData(gridData.map((element: any) => {
            if (element.Panorama.PrimarySN == selectedPanorama.Panorama.PrimarySN) {
                return { ...settings };
            } else {
                return { ...element };
            }
        }));
    }

    const createNewPanorama = (payload: any) => {
        setTaskInProgress(true);
        if("CloudManager" in payload) {
            dataProvider.update("settings", { payload, forceApiUrl: ForceApiUrl })
            .then(async (response: any) => {
                if (response.data) {
                    setGridData([...gridData, { ...response.data }]);
                    setSelectedPanorama(undefined);
                    setSidePanel("");
                } else {
                    toast.error(response?.error?.error, { toastId: "settings-update" });
                }
            })
            .catch((e: any) => {
                toast.error(e?.error?.error, { toastId: "settings-update" });
            })
            .finally(() => {
                setSidePanel("");
                setTaskInProgress(false)
            });
        } else if("Panorama" in payload) {
             //@ts-ignore
            dataProvider.update("settings", { payload, forceApiUrl: ForceApiUrl  })
            .then(async (response: any) => {
                if (response.data) {
                    setGridData([...gridData, { ...response.data }]);
                    setSelectedPanorama(undefined);
                    setSidePanel("");
                    if (response.data.Status && response.data.LogAnalytics?.CortexDataLake?.Id) {
                        setSelectedPanorama(response.data);
                        setModalState('alreadyLinked');
                    }
                } else {
                    toast.error(response?.error?.error, { toastId: "settings-update" });
                }
            })
            .catch((e: any) => {
                toast.error(e?.error?.error, { toastId: "settings-update" });
            })
            .finally(() => {
                setSidePanel("");
                setTaskInProgress(false)
            });
        }
    };

    const updatePanorama = (payload: any) => {
        setTaskInProgress(true);
        if("CloudManager" in payload) {
            dataProvider.update("settings", { payload, forceApiUrl: ForceApiUrl })
            .then(async (response: any) => {
                if (response.data) {
                    updateGridData(response.data);
                    setSelectedPanorama(undefined);
                    setSidePanel("");
                } else {
                    toast.error(response?.error?.error, { toastId: "settings-update" });
                }
            })
            .catch((e: any) => {
                toast.error(e?.error?.error, { toastId: "settings-update" });
            })
            .finally(() => {
                setSidePanel("");
                setTaskInProgress(false);
                loadData();
            });
        }
        else if("Panorama" in payload) {
            if(payload.Status) delete(payload['Status']);
        //@ts-ignore
        dataProvider.update("settings", { payload: payload, forceApiUrl: ForceApiUrl  })
            .then(async (response: any) => {
                if (response.data) {
                    updateGridData(response.data);
                    setSelectedPanorama(undefined);
                    setSidePanel("");
                } else {
                    toast.error(response?.error?.error, { toastId: "settings-update" });
                }
            })
            .catch((e: any) => {
                toast.error(e?.error?.error, { toastId: "settings-update" });
            })
            .finally(() => {
                setSidePanel("");
                setTaskInProgress(false);
                loadData();
            });
        }
    };

    const updateSettingsLink = () => {
        const CspSupportAccountId = getCSPIdFromSN(selectedPanorama?.Panorama.PrimarySN, panoramas.IntegrationsAll);
        const payload = {
            LinkId: selectedPanorama.LinkId,
            LinkName: selectedPanorama.LinkName,
            Panorama: {
                ...selectedPanorama.Panorama,
                CspSupportAccountId
            },
            LogAnalytics: {
                Enabled: true,
                CdlStatus: "Inactive"
            }
        };

        //@ts-ignore
        dataProvider.update("settings", { payload: payload, forceApiUrl: ForceApiUrl }).then(async (response: any) => {
            if (response.data) {
                updateGridData(response.data);
            } else {
                toast.error(response?.error?.error, { toastId: "settings-update-link" });
            }
        }).catch((e: any) => {
            toast.error(e?.error?.error, { toastId: "settings-update-link" });
        }).finally(() => {
            setTaskInProgress(false);
            setModalState("");
            setSidePanel("");
        });
    };

    const unlink = async (data: any = undefined) => {
        if(data?.hasOwnProperty('CloudManager')) {
            setModalState("unlinkCMConfirm")
        } else if(data?.hasOwnProperty('Panorama')) {
            setTaskInProgress(true);
            dataProvider.describe('dgs', '', { linkId: data.LinkId })
                .then((response: any) => {
                    if (response.data) {
                        if (response.data?.CloudDGS && response.data?.CloudDGS.length > 0) {
                            setDGS({ FMSOnboarded: !!response.data?.TenantInfo?.FMSOnboarded, CloudDGS: [...response.data?.CloudDGS] });
                            setModalState('unlinkError');
                        } else {
                            setDGS({ FMSOnboarded: true, CloudDGS: [] });
                            setModalState('unlinkConfirm');
                        }
                    } else {
                        toast.error(response?.error?.error, { toastId: "settings-device-groups-unlink" });
                    }
                })
                .catch((e: any) => toast.error(e?.error?.error, { toastId: "settings-device-groups-unlink" }))
                .finally(() => {
                    setSidePanel("");
                    setTaskInProgress(false);
                });
        }
    };

    const unlinkConfirm = () => {
        // at this point cspId must exist
        var CspSupportAccountId = getCSPIdFromSN(selectedPanorama.Panorama.PrimarySN, panoramas?.IntegrationsAll);
        if (!CspSupportAccountId) {
            CspSupportAccountId = selectedPanorama.Panorama?.CspSupportAccountId;
        }
        const PrimarySN = selectedPanorama.Panorama.PrimarySN;
        const payload = {
            LinkId: selectedPanorama.LinkId,
            Panorama: {
                CspSupportAccountId,
                PrimarySN,
                Action: "unlink"
            },
            LogAnalytics: {
                Enabled: false
            }
        };
        setTaskInProgress(true);

        dataProvider
            //@ts-ignore
            .update("settings", { payload: payload, forceApiUrl: ForceApiUrl }).then(async (response: any) => {
                if (response.data) {
                    //setSettings(response.data);
                    setSidePanel("");
                } else {
                    toast.error(response?.error?.error, { toastId: "settings-update" });
                }
            })
            .catch((e: any) => {
                toast.error(e?.error?.error, { toastId: "settings-update" });
            })
            .finally(() => {
                setTaskInProgress(false);
                setModalState("");
                loadData();
            });
    };

    const unlinkCMConfirm = () => {
        const integrationsAll = cloudManagers?.IntegrationsAll || [];
        const integrationMap = generateCloudManagerIntegrationsMap(integrationsAll).flat();
        const payload = {
            LinkName: selectedPanorama?.LinkName,
            LinkId: selectedPanorama?.LinkId,
            CloudManager: {
                Action: 'unlink',
                TsgId: selectedPanorama?.CloudManager?.TsgId,
                TenantName: selectedPanorama?.CloudManager?.TenantName,
                TenantId: selectedPanorama?.CloudManager?.TenantId,
                Region: selectedPanorama?.CloudManager?.Region || getRegion(selectedPanorama?.CloudManager?.TenantId, integrationMap),
                CspSupportAccountId: getCSPIdFromTenantName(selectedPanorama?.CloudManager?.TenantId, integrationsAll)
            },
        }
        setTaskInProgress(true);
        dataProvider.update("settings", { payload, forceApiUrl: ForceApiUrl })
        .then(async (response: any) => {
            if (response.data) {
                updateGridData(response.data);
                setSidePanel("");
            } else {
                toast.error(response?.error?.error, { toastId: "settings-update" });
            }
        })
        .catch((e: any) => {
            toast.error(e?.error?.error, { toastId: "settings-update" });
        })
        .finally(() => {
            setTaskInProgress(false);
            setModalState("");
            loadData();
        });
    }

    useEffect(() => {
        loadData();
    }, []);

    // TODO confirm if we will required recursive next token calls
    // useEffect(() => {
    //     if (nextToken) {
    //         loadData();
    //     }
    // }, [nextToken])

    const toolbarActions: ITableToolbar[] = [];
    toolbarActions.push({
        type: "button_with_tooltip",
        title: translate('resources.integrations.actions.add'),
        dataMetrics: "cloudngfw-integrations-add-panorama-btn",
        action: () => setSidePanel('addPanorama'),
        appearance: 'primary',
        disabled: !panoramas || !reduxState.support.cspEnable,
        showTooltip: !reduxState.support.cspEnable
    });

    const onRowClick = (data: any) => {
        setSelectedPanorama(data);
        setSidePanel('panoramaDetail');
    };

    const getStatusIcon = (status: string) => {
        if (status === "active") {
            return classes.circleActive;
        }
        if (status === "pending") {
            return classes.circlePending;
        }
        return classes.circleInActive;
    };

    const getStatusText = (status: string) => {
        if (status === "active") {
            return translate(`resources.integrations.values.active`);
        }
        if (status === "pending") {
            return translate(`resources.integrations.values.pending`);
        }
        return translate(`resources.integrations.values.inactive`);
    };

    const buildDeviceName = (serial: any) => {
        if (panoramas?.IntegrationsAll?.length) {
            panoramas.IntegrationsAll.forEach((panorama: any) => {
                panorama.Integrations.forEach((integration: any) => {
                    if (integration?.Panorama?.SerialNumber === serial) {
                        serial = <>{serial} {integration.Panorama.DeviceName ?
                            <i>({integration.Panorama.DeviceName})</i> : null}</>;
                    }
                });
            });
        }
        return serial;
    };

    const prepareData = (rowData: any) => rowData.map((data: any) => {
        return ({
        Type: data?.hasOwnProperty('CloudManager') ? <span className={classes.cellStyles}>{'Strata Cloud Manager'}</span> : <span className={classes.cellStyles}>{'Panorama'}</span>,
        LinkId: data.LinkId,
        LinkIdJSX: (data?.Status !== "Pending") ? <span className={`${classes.cellStyles} ${nameClass.blueColor}`} onClick={(evt) => onRowClick(data)} title={data.LinkId}>{data.LinkId}</span>
        : data.LinkId,
        LinkName: data.LinkName,
        LinkNameJSX: <span className={classes.cellStyles} title={data.LinkName} >{data.LinkName}</span>,
        PrimarySN: data.Panorama?.PrimarySN,
        PrimarySNJSX: <span className={classes.cellStyles}>{data?.hasOwnProperty('CloudManager')? data?.CloudManager?.TenantName : buildDeviceName(data?.Panorama?.PrimarySN)}</span>,
        Status: data.Status,
        StatusJSX: (
            <div className={classes.iconAndText}>
                <div className={`${classes.circle} ${getStatusIcon(data.Status?.toLowerCase())}`}></div>
                {getStatusText(data.Status?.toLowerCase())}
            </div>),
        LogFordwardingAndAnalytics: data.LogAnalytics?.Enabled ? translate('resources.integrations.values.enabled') : translate('resources.integrations.values.disabled'),
        LogFordwardingAndAnalyticsJSX: (
            <div className={classes.iconAndText}>
                {data?.LogAnalytics?.Enabled && <CircleCheckIcon size="sm" className={classes.enabledColor} />}
                {!data?.LogAnalytics?.Enabled && <BanIcon size="sm" className="tw-text-red-600 dark:tw-text-dark-bg-red" />}
                <div>{data.LogAnalytics?.Enabled
                    ? translate('resources.integrations.values.enabled')
                    : translate('resources.integrations.values.disabled')}
                </div>
            </div>),
        SecurityServices: translate(`resources.integrations.fields.checkDetails`),
        SecurityServicesJSX: (
            data?.hasOwnProperty('CloudManager') ? <></> : <span
                className={`${classes.cellStyles} ${nameClass.blueColor}`}
                onClick={(evt) => {
                    setSidePanel("securityServices")
                    setSelectedPanorama(data);
                }}
            >{translate(`resources.integrations.fields.checkDetails`)}</span>
        ),
        ActionsJSX: (data.Status === 'Active'
            ? (<div title={data?.hasOwnProperty('CloudManager') ? translate(`resources.integrations.actions.unlinkSCM`) : translate(`resources.integrations.actions.unlinkPanorama`)} className={classes.action} onClick={() => {
                setSelectedPanorama(data);
                unlink(data);
            }}>
                <UnlinkIcon size="sm" />
            </div>)
            : <></>),
        UrlJSX: data.Url
    })});

    const columns: any[] = [
        {
            accessor: 'Type',
            Header: translate(`resources.integrations.fields.type`),
            columnSize: 1.5,
            Cell: ({ row }: any) => row?.original?.Type,
            sortType: 'basic',
        },
        {
            accessor: 'LinkName',
            Header: translate(`resources.integrations.fields.LinkName`),
            columnSize: 2,
            Cell: ({ row }: any) => row?.original?.LinkNameJSX,
            sortType: 'basic',
        },
        {
            accessor: 'LinkId',
            Header: translate(`resources.integrations.fields.linkID`),
            columnSize: 3,
            Cell: ({ row }: any) => row?.original?.LinkIdJSX,
            sortType: 'basic',
        },
        {
            accessor: 'PrimarySN',
            Header: translate(`resources.integrations.fields.serialNumber`),
            columnSize: 2.5,
            Cell: ({ row }: any) => row?.original?.PrimarySNJSX,
            sortType: 'basic',
        },
        {
            accessor: 'Status',
            Header: translate(`resources.integrations.fields.policyManagementStatus`),
            columnSize: 1.7,
            Cell: ({ row }: any) => row?.original?.StatusJSX,
            sortType: 'basic',
        },
        {
            accessor: 'LogFordwardingAndAnalytics',
            Header: translate(`resources.integrations.fields.logForwarding`),
            columnSize: 1.5,
            Cell: ({ row }: any) => row?.original?.LogFordwardingAndAnalyticsJSX,
            sortType: 'basic',
        },
        {
            accessor: 'SecurityServices',
            Header: translate(`resources.integrations.fields.securityServices`),
            columnSize: 1.5,
            Cell: ({ row }: any) => row?.original?.SecurityServicesJSX,
            sortType: 'basic',
            disableSortBy: true
        },
        {
            Header: translate(`resources.integrations.fields.actions`),
            columnSize: 1.5,
            Cell: ({ row }: any) => row?.original?.ActionsJSX,
            sticky: true,
            disableSortBy: true
        },
    ];

    return (
        <>
            <PANTitle divider title={translate(`resources.integrations.title`)} paddingContainer="16px" />
            <PANWDSBannerContainer/>
            <PANWDSTableLight
                columns={columns}
                data={prepareData(gridData)}
                toolbarActionsArray={toolbarActions}
                title={translate(`resources.integrations.listName`)}
                offsetTableHeight={153}
                loading={loading}
                // backgroundLoading={recursiveLoading}
                lockRows={taskInProgress}
                searchFilterRequired
                dataMetrics="cloudngfw-panoramas-table"
                dataTestId="cloudngfw-panoramas-table"
                enableRowSelect={false}
                showToolbar
            />
            {sidePanel === 'panoramaDetail' && <IntegrationsDetailPanel
                close={() => {
                    setSelectedPanorama(undefined);
                    setSidePanel("");
                }}
                taskInProgress={taskInProgress}
                panoramas={panoramas}
                cloudManagers={cloudManagers}
                selectedPanorama={selectedPanorama}
                regions={regions}
                saveRecord={updatePanorama}
                onClickSecurityService={() => {
                    setShowBackButton(true);
                    setSidePanel("securityServices");
                }}
            />}
             {sidePanel === 'addPanorama' && <AddEditPolicyPanel
                close={() => setSidePanel("")}
                cancel={() => {
                    setSidePanel("");
                    setSelectedPanorama(undefined);
                }}
                panoramaRecord={getAvailablePanoramas()}
                cloudManagerRecord={getAvailableCloudManager()}
                record={undefined}
                saveRecord={createNewPanorama}
                taskInProgress={taskInProgress}
                regions={regions}
                unlink={unlink}
            />}
            {sidePanel === 'securityServices' &&
                <SecurityServices
                    onClose={() => {
                        setSidePanel("");
                        setSelectedPanorama(undefined);
                    }}
                    onCallback={() => {
                        setShowBackButton(false);
                        setSidePanel("panoramaDetail");
                    }}
                    showBackButton={showBackButton}
                    panorama={selectedPanorama}
                />
            }
            {modalState === 'alreadyLinked' && <NotificationModal
                onConfirm={updateSettingsLink}
                close={() => {
                    setModalState("");
                    setSelectedPanorama(undefined);
                }}
                description={translate(`resources.integrations.modal.descriptionLinked`)}
                taskInProgress={taskInProgress}
            />}
            {modalState === 'unlinkConfirm' && <NotificationModal
                onConfirm={unlinkConfirm}
                close={() => {
                    setModalState("");
                    setSelectedPanorama(undefined);
                }}
                description={translate(`resources.integrations.modal.descriptionUnlinkConfirm`)}
                taskInProgress={taskInProgress}
                note={translate(`resources.integrations.modal.noteUnlinkConfirm`)}
            />}
            {modalState === 'unlinkCMConfirm' && <NotificationCMModal
                onConfirm={unlinkCMConfirm}
                close={() => {
                    setModalState("");
                    setSelectedPanorama(undefined);
                }}
                description={translate(`resources.integrations.modal.descriptionCMUnlinkConfirm`)}
                taskInProgress={taskInProgress}
                note={translate(`resources.integrations.modal.noteCMUnlinkConfirm`)}
            />}
            {modalState === 'unlinkError' && <ErrorUnlinkModal
                close={() => {
                    setModalState("");
                    setSelectedPanorama(undefined);
                }}
                dgs={dgs}
                forceUnlink={unlinkConfirm}
                taskInProgress={taskInProgress}
            />}
            {modalState === 'notLinked' && <NotificationModal
                close={() => setModalState("")}
                description={translate(`resources.integrations.modal.descriptionNotLinked`)}
            />}
            {modalState === 'selectCDL' && <SelectCDLModal
                close={() => setModalState("")}
            />}
            {anyUnlinkPending && <IntervalCallerComponent interval={15000} updateCallback={loadData} />}
        </>
    );
}

export default withRouter(IntegrationsList);
