import {FormControl, Grid} from "@material-ui/core";
import {Field, FormSpy, useForm} from "react-final-form";
import {PANWDSRadioField, PANWDSSelectWithSearch} from "../../../../components/PANWDSElements";
import {isRequired} from "../../../../utils/validate";
import {Row} from "../../../../components/FormElements";
import * as React from "react";
import {usePermissions, useTranslate} from "../../../../customHooks";
import {useEffect, useState} from "react";
import {dataProvider} from "../../../../dataProvider";
import * as DataTypes from "../../../../api/FwaasDataTypes";
import {toast} from "../../../../components";
import { OnChange } from "react-final-form-listeners";
import { Banner, Button, Link } from "@panwds/react-ui";
import { useIntl } from "react-intl";
import { RouteUri } from "../../../../routeUri";
import {useHistory} from "react-router";
import { Tooltip } from "@panwds/react-ui";
import {InfoIcon} from "@panwds/icons";
import { find } from "lodash";

const SecurityPolicies = ({region}: any) => {
    const form = useForm();
    const translate = useTranslate();
    const intl = useIntl();
    const history = useHistory();
    const { permissions, rawPermissions } = usePermissions();
    const defaultRulestackItem = {label: translate(`resources.firewallsV2.defaultRulestack`), value: 'Default Rulestack'};
    const [ruleStackChoices, setRuleStackChoices] = useState<any[] | undefined>([defaultRulestackItem]);
    const [panoramas, setPanoramas] = useState<any[] | undefined>(undefined);
    const [cloudManagers, setCloudManagers] = useState<any[] | undefined>(undefined);
    const isTenantAdmin = rawPermissions && find(rawPermissions, { Policy: "TenantAdmin" });

    useEffect(() => {
        dataProvider.get("ruleStacks", {
            data: {
                Candidate: false,
                Running: true,
                Scope: "Local"
            },
            region: region
        }).then(async (response: DataTypes.IFwaasApiResponse) => {
            //@ts-ignore
            let ret: DataTypes.IFwaasApiResponse = {};
            if (response?.data) {
                //@ts-ignore
                let rulestacks = response.data as string[];
                if (rulestacks) {
                    let choices: any = rulestacks.map((rulestack: any) => ({text: rulestack, value: rulestack}));
                    choices = choices.sort((first: any, second: any) => {
                        return first.value.localeCompare(second.value, undefined, {sensitivity: 'base'});
                    });

                    // Add 'Default Rulestack' to the choices
                    choices.unshift(defaultRulestackItem);

                    setRuleStackChoices(choices);
                    return;
                }
            } else {
                toast.error(response?.error, {toastId: "ruleStacks-get"});
            }
            return ret;
        }).catch((e: any) => {
            toast.error(e?.error, {toastId: "ruleStacks-get"});
        });
    }, [region]);

    useEffect(() => {
        let apiCall: { resource: string, url?: string, payload?: any } = {
            resource: "settings",
            payload: { panorama: true }
        }
        if (process.env.REACT_APP_SHOW_CM === "true") {
            apiCall = {
                resource: "integrations",
            }
        }

        dataProvider.describe(apiCall.resource, apiCall.url || "", apiCall?.payload || {})
            .then(async (response: DataTypes.IFwaasApiResponse) => {
                let links = Object.keys(response.data.Links);
                let cmLinks: Array<any> = [];
                let pnLinks: Array<any> = [];
                links.map(link => {
                    const value = response.data.Links[link].LinkName ? `${link} (${response.data.Links[link].LinkName})` : link;
                    if (response.data.Links[link]?.hasOwnProperty('CloudManager') && value !== undefined && response.data.Links[link].Status === 'Active') {
                        cmLinks.push({text: value, value: link});
                    }
                    if (response.data.Links[link]?.hasOwnProperty('Panorama') && value !== undefined) {
                        pnLinks.push({text: value, value: link});
                    }
                });
                setPanoramas(pnLinks);
                setCloudManagers(cmLinks);
            })
            .catch((e: any) => {
                toast.error(e?.error?.error, {toastId: "support-describe"});
            })
    }, [form]);

    const getPolicyManagedList = () => {
        let PolicyManagedList = [
            {
                label: (
                    <div className="tw-flex tw-items-center tw-flex-row">
                        <div>{translate(`resources.firewallsV2.rulestack`)}</div>
                        <div><Tooltip label={translate(`resources.firewallsV2.fwCreate.rulestackTooltip`)}>
                                <InfoIcon style={{ paddingLeft: "10px" }} size="xs" />
                            </Tooltip>
                        </div>
                    </div>
                ),
                value: "Rulestack"
            },
            {
                label: (
                    <div className="tw-flex tw-items-center tw-flex-row">
                        <div>{translate(`resources.firewallsV2.panorama`)}</div>
                        <div><Tooltip label={translate(`resources.firewallsV2.fwCreate.panoramaTooltip`)}>
                                <InfoIcon style={{ paddingLeft: "10px" }} size="xs" />
                            </Tooltip>
                        </div>
                    </div>
                ),
                value: "Panorama"
            },
        ];
        if (process.env.REACT_APP_SHOW_CM === "true") {
            PolicyManagedList.splice(1, 0,
                {
                    label: (
                        <div className="tw-flex tw-items-center tw-flex-row">
                            <div>{translate(`resources.firewallsV2.strataCloudManager`)}</div>
                            <div><Tooltip label={translate(`resources.firewallsV2.fwCreate.scmTooltip`)}>
                                    <InfoIcon style={{ paddingLeft: "10px" }} size="xs" />
                                </Tooltip>
                            </div>
                        </div>
                    ),
                    value: "Strata Cloud Manager"
                });
        }

        return PolicyManagedList;
    }

    const handlePolicyManaged = (selected: any, previous: any) => {
        if (previous === "Rulestack") {
            form.change("RuleStackName", undefined);
        }
        if (previous === "Strata Cloud Manager") {
            form.change("CMLinkId", undefined);
        }
        if (previous === "Panorama") {
            form.change("LinkId", undefined);
        }
    }

    return (
        <>
            <Row>
                <Grid item xs={12} sm={12} className="noPadding">
                    <FormControl fullWidth>
                        <Field
                            name="PolicyManaged"
                            title={"Managed By"}
                            row
                            // @ts-ignore
                            component={PANWDSRadioField}
                            options={getPolicyManagedList()}
                            validate={isRequired}
                            initialValue="Rulestack"
                            //disabled={MultiVpcEnabled}
                            dataMetrics={"cloudngfw-firewall-create-policy-managed"}
                        />
                        <OnChange name={"PolicyManaged"}>
                            {(selected: any, previous) => handlePolicyManaged(selected, previous)}
                        </OnChange>
                    </FormControl>
                </Grid>
            </Row>
            <Row>
                <Grid item xs={12} sm={12} className="bottomPadding">
                    <FormSpy subscription={{ values: true }}>
                        {({ values, ...rest }) => {
                            if (values.PolicyManaged === "Rulestack") {
                                return (
                                    <FormControl fullWidth>
                                        <Field
                                            name="RuleStackName"
                                            row
                                            // @ts-ignore
                                            component={PANWDSSelectWithSearch}
                                            title={translate(`resources.firewallsV2.localRulestack`)}
                                            items={ruleStackChoices}
                                            loading={ruleStackChoices === undefined}
                                            dataMetrics={"cloudngfw-firewall-create-rulestack-name"}
                                            initValue={ruleStackChoices && ruleStackChoices[0]?.value}
                                        />
                                    </FormControl>
                                );
                            } else return null;
                        }}
                    </FormSpy>
                    <FormSpy subscription={{ values: true }}>
                        {({ values, ...rest }) => {
                            if (values.PolicyManaged === "Strata Cloud Manager") {
                                return (
                                    <>
                                        {(cloudManagers === undefined || cloudManagers?.length === 0) && isTenantAdmin && (
                                             <div className="tw-space-y-4 tw-mb-4">
                                                <Banner
                                                    type="inline"
                                                    appearance="warning"
                                                    showIcon
                                                    dataMetrics={"cloudngfw-noCloudManagerMessage-notification-redirect"}
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        history.push(RouteUri.Integrations)}}
                                                    actions={
                                                        <>
                                                            <Button appearance="primary" size="sm" dataMetrics={"cloudngfw-swupgrade-notification-redirect"} onClick={(e) => {
                                                                e.preventDefault();
                                                                history.push(RouteUri.Integrations)}}
                                                            >
                                                                {translate(`resources.firewallsV2.createPolicyManager`)}
                                                            </Button>
                                                        </>
                                                    }
                                                >
                                                    {translate(`resources.firewallsV2.noCloudManagerMessage`)}
                                                </Banner>
                                            </div>
                                        )}
                                        {(cloudManagers === undefined || cloudManagers?.length === 0) && !isTenantAdmin && (
                                             <div className="tw-space-y-4 tw-mb-4">
                                                <Banner
                                                    type="inline"
                                                    appearance="warning"
                                                    showIcon
                                                    dataMetrics={"cloudngfw-noSPMessageForNonTA-notification-redirect-noTA"}
                                                >
                                                    {translate(`resources.firewallsV2.noSPMessageForNonTA`)}
                                                </Banner>
                                            </div>
                                        )}
                                        <FormControl fullWidth>
                                            <Field
                                                name="CMLinkId"
                                                row
                                                // @ts-ignore
                                                component={PANWDSSelectWithSearch}
                                                title={translate(`resources.firewallsV2.policyManager`)}
                                                items={cloudManagers || []}
                                                loading={cloudManagers === undefined}
                                                dataMetrics={"cloudngfw-firewall-create-link-id"}
                                                muted={cloudManagers === undefined && isTenantAdmin ?
                                                    intl.formatMessage(
                                                    {
                                                        id: "resources.firewallsV2.policyManaged.strataDescription",
                                                    },
                                                    {
                                                        integrationPage: (
                                                            <Link
                                                                dataMetrics="cloudngfw-scm-page-link"
                                                                external
                                                                onClick={(e) => {
                                                                    e.preventDefault();
                                                                    history.push(RouteUri.Integrations);
                                                                }}
                                                            >
                                                                {translate(`resources.firewallsV2.policyManaged.integrationPage`)}
                                                            </Link>
                                                        ),
                                                    }
                                                )
                                                : isTenantAdmin ? translate(`resources.firewallsV2.linkedCloudManagerMessage`) : null
                                                }
                                                required
                                                validate={isRequired}
                                            />
                                        </FormControl>
                                    </>
                                );
                            } else return null;
                        }}
                    </FormSpy>
                    <FormSpy subscription={{ values: true }}>
                        {({ values, ...rest }) => {
                            if (values.PolicyManaged === "Panorama") {
                                return (
                                    <>
                                        {(panoramas === undefined || panoramas?.length === 0) && isTenantAdmin && (
                                            <div className="tw-space-y-4 tw-mb-4">
                                                <Banner
                                                    type="inline"
                                                    appearance="warning"
                                                    showIcon
                                                    dataMetrics={"cloudngfw-noPanoramaMessage-notification-redirect"}
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        history.push(RouteUri.Integrations)}}
                                                    actions={
                                                        <>
                                                            <Button appearance="primary" size="sm" dataMetrics={"cloudngfw-swupgrade-notification-redirect"} onClick={(e) => {
                                                                    e.preventDefault();
                                                                    history.push(RouteUri.Integrations)}}>
                                                                {translate(`resources.firewallsV2.createPolicyManager`)}
                                                            </Button>
                                                        </>
                                                    }
                                                >
                                                    {translate(`resources.firewallsV2.noPanoramaMessage`)}
                                                </Banner>
                                            </div>
                                        )}
                                         {(panoramas === undefined || panoramas?.length === 0) && !isTenantAdmin && (
                                             <div className="tw-space-y-4 tw-mb-4">
                                                <Banner
                                                    type="inline"
                                                    appearance="warning"
                                                    showIcon
                                                    dataMetrics={"cloudngfw-noSPMessageForNonTA-panorama-notification-redirect-noTA"}
                                                >
                                                    {translate(`resources.firewallsV2.noSPMessageForNonTA`)}
                                                </Banner>
                                            </div>
                                        )}
                                        <FormControl fullWidth>
                                            <Field
                                                name="LinkId"
                                                row
                                                // @ts-ignore
                                                component={PANWDSSelectWithSearch}
                                                title={translate(`resources.firewallsV2.linkedPanorama`)}
                                                items={panoramas || []}
                                                loading={panoramas === undefined}
                                                dataMetrics={"cloudngfw-firewall-create-link-id"}
                                                muted={panoramas === undefined && isTenantAdmin ?
                                                    intl.formatMessage(
                                                    {
                                                        id: "resources.firewallsV2.policyManaged.panoramaDescription",
                                                    },
                                                    {
                                                        integrationPage: (
                                                            <Link
                                                                dataMetrics="cloudngfw-scm-page-link"
                                                                external
                                                                onClick={(e) => {
                                                                    e.preventDefault();
                                                                    history.push(RouteUri.Integrations);
                                                                }}
                                                            >
                                                                {translate(`resources.firewallsV2.policyManaged.integrationPage`)}
                                                            </Link>
                                                        ),
                                                    }
                                                )
                                                : isTenantAdmin ? translate(`resources.firewallsV2.linkedPanoramaMessage`) : null
                                                }
                                                required
                                                validate={isRequired}
                                            />
                                        </FormControl>
                                    </>
                                );
                            } else return null;
                        }}
                    </FormSpy>
                </Grid>
            </Row>
        </>
    );
};

export default SecurityPolicies;
